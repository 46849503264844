let baseClass = require('@/client/extensions/composition/asyncOperations/responseAdapters/interface.class.js')

class RawAdapter extends baseClass.default {

  type = 'raw'

  constructor (options) {
    super(options)
    return this
  }

  parse (response, options) {
    return { 'data': response }
  }

}

export default (options) => {
  return new RawAdapter(options)
};
